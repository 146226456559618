import { BaseModel } from './base.model';

export class UserProductModel extends BaseModel {
  id: number;
  userId: number;
  product: string;
  productUserId: number;
  productInstanceId: number;
  createdAt: string;
  updatedAt: string;

  constructor(userId?: number, product?: string, productUserId?: number, productInstanceId?: number) {
    super();
    this.userId = userId;
    this.product = product;
    this.productUserId = productUserId;
    this.productInstanceId = productInstanceId;
  }
}
