/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';
import { Storage } from '@capacitor/storage';
import { UserProductModel } from '../models/user-product.model';
import { ModelsMapper } from '../helpers/models-mapper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserObservable: BehaviorSubject<UserModel|null> = new BehaviorSubject<UserModel|null>(null);
  authTokenObservable: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);

  constructor(
    private httpClient: HttpClient
  ) {
    this.init();
  }

  async init() {
    const storedUser = await this.getStoredUser();
    if (!!storedUser) { this.currentUserObservable.next(storedUser); }

    const authToken = await this.getAuthToken();
    if (authToken) { this.authTokenObservable.next(authToken); }
  }

  async login(username: string, password: string): Promise<boolean> {
    return new Promise((resolve: CallableFunction) => {
      this.httpClient.post(`${environment.apiAuthBaseUrl}/auth/login`, { username, password }, {
        headers: {}
      })
      .toPromise()
      .then(async (response: any) => {
        if (!!response === false || !!response.error) {
          console.log(`ERROR on -> ${this.constructor.name}.login() then `, response);
          return resolve(false);
        }

        // Store user
        const userModel = ModelsMapper.fromUserObjectWithSnakeCaseToModel(response.data);
        await this.setStoredUser(userModel);

        // Store token
        await this.setAuthToken(response.token);

        return resolve(true);
      })
      .catch((error: any) => {
        console.log(`ERROR on -> ${this.constructor.name}.login() catch `, error);
        return resolve(false);
      });
    });
  }

  async logout() {
    return new Promise(async (resolve: CallableFunction) => {
      this.httpClient.post(`${environment.apiAuthBaseUrl}/auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${this.authTokenObservable.getValue()}`
        }
      })
      .toPromise()
      .then(async (response: any) => {
        if (!!response === false || !!response.error) {
          console.log(`ERROR on -> ${this.constructor.name}.login() then -> `, response);
          return resolve(false);
        }

        // Remove local stored data
        await this.deleteStoredUser();
        await this.deleteAuthToken();

        return resolve(true);
      })
      .catch((error: any) => {
        console.log(`ERROR on -> ${this.constructor.name}.login() catch -> `, error);
        return resolve(false);
      });
    });
  }

  async setStoredUser(currentUser: UserModel) {
    await Storage.set({ key: 'currentUser', value: JSON.stringify(currentUser) });
    this.currentUserObservable.next(currentUser);
  }

  async getStoredUser() {
    const currentUser: any = await Storage.get({ key: 'currentUser' });
    if (!!currentUser === false || !!currentUser.value === false) {
      return null;
    }
    const currentUserObj = JSON.parse(currentUser.value);
    const user = UserModel.fromObject(currentUserObj, new UserModel());
    user.products = currentUserObj.products.map((item) => UserProductModel.fromObject(item, new UserProductModel()));
    return user;
  }

  async deleteStoredUser() {
    await Storage.remove({ key: 'currentUser' });
    this.currentUserObservable.next(null);
  }

  async setAuthToken(authToken: string) {
    await Storage.set({ key: 'authToken', value: authToken });
  }

  async getAuthToken() {
    const authToken = await Storage.get({ key: 'authToken' });
    return authToken.value;
  }

  async deleteAuthToken() {
    await Storage.remove({ key: 'authToken' });
  }

}
