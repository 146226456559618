import { UserModel } from '../models/user.model';
import { UserProductModel } from '../models/user-product.model';

export class ModelsMapper {

  public static fromUserObjectWithSnakeCaseToModel(currentUserObj: any): UserModel {
    const user = UserModel.fromObjectWithSnakeCase(currentUserObj, new UserModel());
    user.products = currentUserObj.products.map((item) => ModelsMapper.fromUserProductObjectWithSnakeCaseToModel(item));

    // Aux fields
    user.auxHasFacebook = !!user.products && user.products.findIndex(p => p.product === 'facebook') !== -1;

    return user;
  }

  public static fromUserProductObjectWithSnakeCaseToModel(productObj: Array<any>) {
    return UserProductModel.fromObjectWithSnakeCase(productObj, new UserProductModel());
  }

  public static fromUserModelToObjectWithSnakeCase(user: UserModel) {
    const userObj: any = user.toObjectWithSnakeCase();
    userObj.products = user.products.map(item => ModelsMapper.fromUserProductModelToObjectWithSnakeCase(item));
    return userObj;
  }

  public static fromUserProductModelToObjectWithSnakeCase(userProduct: UserProductModel) {
    return userProduct.toObjectWithSnakeCase();
  }

}
