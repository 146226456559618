import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { UserModel } from './models/user.model';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  currentUser: UserModel|null;

  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Utenti', url: '/users', icon: 'people' },
    // { title: 'Facebook', url: '/facebook', icon: 'logo-facebook' }
  ];

  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private powerAuthService: AuthService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.powerAuthService.currentUserObservable.subscribe((user: UserModel|null) => {
      this.currentUser = user;
    });
  }

  async logout() {
    const loading = await this.loadingController.create({ message: 'Disconnessione in corso...' });
    loading.present();
    const logoutResult = await this.powerAuthService.logout();
    loading.dismiss();

    if (!!logoutResult === false) {
      const alert = await this.alertController.create({
        header: 'Logout fallito',
        message: 'Riprova tra poco dopo aver ricaricato la pagina.',
        buttons: [{
          text: 'OK',
          role: 'dismiss'
        }]
      });
      return alert.present();
    }

    this.router.navigate(['/']);
  }

}
