import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private powerAuthService: AuthService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = await this.powerAuthService.getStoredUser();
    const authToken = await this.powerAuthService.getAuthToken();

    if (!!currentUser === false || !!authToken === false) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

}
