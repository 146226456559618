import { BaseModel } from './base.model';
import { UserProductModel } from './user-product.model';

export class UserModel extends BaseModel {
  id: number;
  role: string;
  tokenAbilities: string;
  name: string;
  username: string;
  email: string;
  password: string;
  createdAt: string;
  updatedAt: string;
  products: Array<UserProductModel>|null;

  // Aux fields
  auxShowDetails = false;
  auxHasFacebook = true;
}
