export class BaseModel {

  static fromObjectWithSnakeCase(sourceObject, instance) {
      Object.keys(sourceObject).forEach((propKeySnakeCase) => {
          const propKey = propKeySnakeCase.replace(/([-_]\w)/g, g => g[1].toUpperCase());
          instance[propKey] = sourceObject[propKeySnakeCase];
      });
      return instance;
  }

  static fromObject(sourceObject, instance) {
      Object.keys(sourceObject).forEach((key) => {
        instance[key] = sourceObject[key];
      });
      return instance;
  }

  toObjectWithSnakeCase(removeNulls?) {
      const object = {};
      for (const camelCaseKey of Object.keys(this)) {
          if (!!removeNulls === true && !!this[camelCaseKey] === false) { continue; }
          const snakeCaseKey = camelCaseKey.replace(/[\w]([A-Z])/g, m => `${m[0]}_${m[1]}`).toLowerCase();
          object[snakeCaseKey] = this[camelCaseKey];
      }
      return object;
  }

  toObject(removeNulls?) {
      const object = {};
      for (const key of Object.keys(this)) {
          if (!!removeNulls === true && !!this[key] === false) { continue; }
          object[key] = this[key];
      }
      return object;
  }
}
